import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["input", "image", "submit"];
  }

  pickFile() {
    this.inputTarget.click();
  }

  async preview() {
    const imagePreview = this.imageTarget;
    const submit = this.submitTarget;

    const reader = new FileReader();
    reader.onload = (e) => {
      if(imagePreview instanceof HTMLImageElement) {
        imagePreview.src = e.target.result;
      }

      if(imagePreview instanceof HTMLDivElement) {
        imagePreview.style.backgroundImage = `url("${e.target.result}")`;
      }

      submit.disabled = false;
    };
    reader.readAsDataURL(this.inputTarget.files[0]);
  }
}
